<template>

  <b-card>

    <b-row>
      <b-col
        cols="12"
        sm="4"
      >
        <b-form-group>
          <label for="filter_crops">Filter By Crops</label>
          <v-select
            id="filter_crops"
            v-model="filterGraph.crop"
            :options="list.crops"
            label="crop_name"
            placeholder="-- Please Select Crops --"
          >
            <template #option="{ crop_name }">
              <span>{{ crop_name }}</span>
            </template>
            <template #no-options="">
              No Available Crops
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <b-form-group>
          <label for="filter_cropping_season">Filter By Cropping Season</label>
          <v-select
            id="filter_cropping_season"
            v-model="filterGraph.cropping_season"
            :options="list.cropping_seasons"
            label="text"
            placeholder="-- Please Select Cropping Season --"
          >
            <template #option="{ text }">
              <span>{{ text }}</span>
            </template>
            <template #no-options="">
              No Available Cropping Season
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <b-form-group>
          <label for="cropping_year">Filter By Cropping Year</label>
          <v-select
            id="cropping_year"
            v-model="filterGraph.cropping_year"
            :options="list.cropping_years"
            label="text"
            placeholder="-- Please Select Cropping Year --"
          >
            <template #option="{ text }">
              <span>{{ text }}</span>
            </template>
            <template #no-options="">
              No Available Cropping Year
            </template>
          </v-select>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        class="mt-5"
      >
        <Graph
          :data="dataFungicides"
          class="my-2"
        />
      </b-col>
    </b-row>
    <section class="mt-5">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>
              Region/Province
            </th>
            <th
              colspan="5"
              class="text-center"
            >
              Major Fungicides Planted
            </th>

          </tr>
        </thead>
        <tbody
          v-for="(region, regionKey) in list.data"
          :key="regionKey"
        >
          <tr
            :key="regionKey"
            aria-colspan="6"
            style="border-bottom: 1px solid  #dee2e6;"
          >

            <td colspan="6">
              <strong>{{ region.region_name }}</strong>
            </td>

          </tr>
          <tr
            v-for=" (province, provinceKey) in region.provinces"
            :key="`provinces-${provinceKey}`"
            style="border-bottom: 1px solid  #dee2e6;"
          >
            <td>
              {{ province.province_name }}
            </td>

            <td
              v-for=" (fungicide, fungicideKey) in province.fungicides"
              :key="`fungicide-${fungicideKey}`"
            >
              {{ fungicide.fungicide_name }}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </b-card>
</template>
<script>

import { SharedStatisticsService, SharedListService } from '@/services'
import Graph from '@/components/dashboard/Graph'

const year = '2023'

const options = {
  series: [],
  labels: [],
  legend: {
    show: true
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return Number(val).toFixed(2) + '%'
    }
  }

}

export default {
  name: 'ManagerFungicides',
  middleware: ['auth', 'manager', 'supervisor'],
  components: {
    Graph
  },

  data () {
    return {
      list: {
        cropping_seasons: [
          { text: 'Wet', value: 'Wet' },
          { text: 'Dry', value: 'Dry' }
        ],
        cropping_years: [
          { text: '2022', value: 2022 },
          { text: '2023', value: 2023 },
          { text: '2024', value: 2024 },
          { text: '2025', value: 2025 },
          { text: '2026', value: 2026 },
          { text: '2027', value: 2027 },
          { text: '2028', value: 2028 },
          { text: '2029', value: 2029 },
          { text: '2030', value: 2030 },
          { text: '2031', value: 2031 },
          { text: '2032', value: 2032 }
        ],
        crops: [],
        data: []
      },
      selected: {
        crop: 'all',
        cropping_year: '2023',
        cropping_season: 'Wet'
      },
      filterGraph: {
        crop: null,
        cropping_year: { text: '2023', value: 2023 },
        cropping_season: { text: 'Wet', value: 'Wet' }
      },

      dataFungicides: {
        year: year,
        series: [],
        options: options
      }
    }
  },

  watch: {
    'filterGraph.crop' (value) {
      if (value) {
        this.selected.crop = value?.id || ''
        this.graphChange()
      }
    },

    'filterGraph.cropping_year' (value) {
      if (value) {
        this.selected.cropping_year = value?.value || ''
        this.graphChange()
      }
    },

    'filterGraph.cropping_season' (value) {
      if (value) {
        this.selected.cropping_season = value?.value || ''
        this.graphChange()
      }
    }
  },

  mounted () {
    this.getStatistics()
    this.getCrops()
  },

  methods: {
    async getStatistics () {
      const { data } = await SharedStatisticsService.getGraphFungicides(`crop=${this.selected.crop}&cropping_year=${this.selected.cropping_year}&cropping_season=${this.selected.cropping_season}`)

      // fungicides
      this.dataFungicides.series = data?.total_count || []
      this.dataFungicides.options = { ...this.dataFungicides.options, labels: data?.data }
      this.list.data = data?.fungicide
    },

    async getCrops () {
      const { data } = await SharedListService.getCrops()
      this.list.crops = data
    },

    graphChange () {
      this.getStatistics()
    }
  }
}
</script>
